<template>
  <div class="max-w-5xl p-3 mx-auto" v-if="website_variables">
    <div class="grid grid-cols-2 gap-10 pt-10 md:pt-10 md:grid-cols-3">
      <div
        class="flex flex-col items-center space-y-3"
        v-for="website_variable in website_variables"
        :key="website_variable.id"
      >
        <img :src="website_variable.photo" class="w-10 h-10 mx-auto" alt="" />
        <h3 class="text-xl text-center font-bold text-[#566861]">
          {{ website_variable.title }}
        </h3>
        <p class="text-xs font-light text-center text-[#566861]">
          {{ website_variable.sub_title }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "ThreeStepSection",
  components: {},
  data() {
    return {
      website_variables: null,
      base_url: this.$store.state.baseUrl,
    };
  },
  mounted() {
    axios
      .get(this.base_url + "second-homepage-variables")
      .then((response) => {
        // console.log(response);
        this.website_variables = response.data.slice(0, 5);
      })
      .catch((error) => console.log(error));
  },
  methods: {
    truncate(str, n) {
      return str.length > n ? str.substr(0, n - 1) + "..." : str;
    },
    sanitizeTitle(title) {
      var slug = "";
      // Change to lower case
      var titleLower = title.toLowerCase();
      // Letter "e"
      slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, "e");
      // Letter "a"
      slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, "a");
      // Letter "o"
      slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, "o");
      // Letter "u"
      slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, "u");
      // Letter "d"
      slug = slug.replace(/đ/gi, "d");
      // Trim the last whitespace
      slug = slug.replace(/\s*$/g, "");
      // Change whitespace to "-"
      slug = slug.replace(/\s+/g, "-");

      return slug;
    },
  },
};
</script>
