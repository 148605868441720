<template>
  <button
    :class="`w-full p-4 mt-2 text-center rounded-none flex items-center justify-center text-sm shadow-sm h-12 text-${color} bg-${bg}`"
  >
    {{ name }}
  </button>
</template>

<script>
export default {
  name: "ButtonView",
  props: {
    name: String,
    bg: String,
    color: String,
  },
};
</script>
