<template>
  <Disclosure
    as="nav"
    v-if="categories"
    :class="{
      'bg-white text-black': isScrolled,
      'bg-transparent text-white': !isScrolled,
    }"
    class="fixed top-0 z-50 w-full text-white"
    v-slot="{ open = !open }"
  >
    <div class="w-full">
      <announcement />
    </div>
    <div class="px-4 mx-auto font-normal text-black lg:w-full lg:px-10">
      <div class="relative flex items-center justify-between h-20">
        <a href="/" class="flex items-center flex-shrink-0">
          <img class="block w-auto h-4" src="/img/logo.png" alt="pokkadaa" />
        </a>
        <div
          class="absolute inset-y-0 right-0 flex flex-col items-center justify-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0"
        >
          <div class="hidden text-sm tracking-wider uppercase sm:block">
            <div
              class="flex items-center justify-center space-x-5 capitalize lg:space-x-10"
            >
              <span v-for="category in categories" :key="category.id">
                <a
                  :href="
                    $router.resolve({
                      name: 'CategoryView',
                      params: {
                        id: category.id,
                        slug: sanitizeTitle(category.name),
                      },
                    }).href
                  "
                  class="font-light hover:underline underline-offset-4"
                  ><span>{{ category.name }}</span></a
                >
              </span>
              <a
                class="font-light hover:underline underline-offset-4"
                href="/collections/shop-all"
                >Shop</a
              >
              <!-- <span class="capitalize">
                <a
                  href="/collections/shop-all"
                  class="flex items-center justify-center w-32 p-2 text-sm font-light text-center text-white bg-[#566861] rounded-full mdmt-0 mdml-4"
                  >Shop Now
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5 ml-1 font-bold stroke-2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                    /></svg
                ></a>
              </span> -->
            </div>
          </div>

          <!-- Profile dropdown -->
        </div>
        <div class="justify-end hidden space-x-3 text-sm lg:space-x-20 md:flex">
          <!-- <a href="" class="font-light hover:underline underline-offset-4">
            search
          </a> -->
          <a
            v-if="!user"
            href="/login"
            class="font-light hover:underline underline-offset-4"
          >
            Login
          </a>

          <div
            @click="openSidebar"
            class="flex w-auto font-light cursor-pointer"
          >
            <span class="hidden lg:flex">Your Order</span>
            <span
              class="flex items-center justify-center w-5 h-5 p-1 ml-2 border rounded-full border-stone-400"
              >{{ count ?? 0 }}</span
            >
          </div>
          <a href="/profile" v-if="user" class="flex font-light">
            {{ user.first_name + " " + user.last_name }}
          </a>
        </div>
        <div class="flex items-center md:hidden">
          <div
            class="flex items-center px-2 space-x-5 rounded-lg md:hidden focus:outline-none focus:shadow-outline"
          >
            <a
              class="font-light hover:underline underline-offset-4"
              href="/collections/shop-all"
              >Shop</a
            >
            <a
              v-if="!user"
              href="/login"
              class="font-light hover:underline underline-offset-4"
            >
              Login
            </a>
            <a href="/profile" v-if="user" class="flex font-light">
              {{ user.first_name + " " + user.last_name }}
            </a>
            <div @click="openSidebar" class="flex font-light cursor-pointer">
              <span
                class="flex items-center justify-center w-5 h-5 p-1 ml-2 border rounded-full border-stone-400"
                >{{ count ?? 0 }}</span
              >
            </div>
          </div>
          <DisclosureButton
            class="flex items-center px-2 rounded-lg md:hidden focus:outline-none focus:shadow-outline"
          >
            <span class="sr-only">Open main menu</span>
            <svg fill="currentColor" viewBox="0 0 20 20" class="w-6 h-6">
              <path
                v-if="!open"
                fill-rule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                clip-rule="evenodd"
              ></path>
              <path
                v-if="open"
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </DisclosureButton>
        </div>
      </div>
    </div>

    <DisclosurePanel class="bg-white sm:hidden">
      <div class="px-2 pt-2 pb-3 space-y-1 text-sm tracking-wider uppercase">
        <span v-for="category in categories" :key="category.id">
          <router-link
            :to="{
              name: 'CategoryView',
              params: {
                id: category.id,
                slug: sanitizeTitle(category.name),
              },
            }"
            class="flex self-center py-2 mt-2 text-sm mdmt-0 mdml-4"
            >{{ category.name }}</router-link
          >
        </span>
      </div>
    </DisclosurePanel>
  </Disclosure>
  <Sidebar />
</template>

<script>
import Sidebar from "@/components/partials/Sidebar.vue";
import CryptoJS from "crypto-js";
import { mapGetters } from "vuex";
import axios from "axios";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import announcement from "./Announcement.vue";

export default {
  name: "HeaderView",
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    announcement,
    Sidebar,
  },

  data() {
    return {
      categories: null,
      base_url: this.$store.state.baseUrl,
      user: null,
      // navigation,
    };
  },
  computed: {
    ...mapGetters("cart", ["count"]),
    ...mapGetters("auth", ["getUser"]),
  },

  mounted() {
    this.$store.commit("loading", true);
    axios
      .get(this.base_url + "categories")
      .then((response) => {
        this.categories = response.data.slice(0, 3);
        this.$store.commit("loading", false);
      })
      .catch((error) => console.log(error));

    const encryptedData = localStorage.getItem("userData");
    if (encryptedData) {
      const bytes = CryptoJS.AES.decrypt(encryptedData, "amazonlike");
      this.user = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      return this.user;
    }
  },
  methods: {
    openSidebar() {
      this.$store.dispatch("cart/openSidebar");
    },
    getFirstLetters(inputString) {
      // Split the input string into words
      const words = inputString.split(" ");

      // Use map to extract the first letter of each word and join them
      const firstLetters = words.map((word) => word[0]).join("");

      return firstLetters;
    },

    sanitizeTitle(title) {
      var slug = "";
      // Change to lower case
      var titleLower = title.toLowerCase();
      // Letter "e"
      slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, "e");
      // Letter "a"
      slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, "a");
      // Letter "o"
      slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, "o");
      // Letter "u"
      slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, "u");
      // Letter "d"
      slug = slug.replace(/đ/gi, "d");
      // Trim the last whitespace
      slug = slug.replace(/\s*$/g, "");
      // Change whitespace to "-"
      slug = slug.replace(/\s+/g, "-");

      return slug;
    },
  },
};
</script>
<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";

const isScrolled = ref(false);

const handleScroll = () => {
  isScrolled.value = window.scrollY > 0;
};

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleScroll);
});
</script>
