<template>
  <div
    class="sticky h-8 p-1 flex justify-center items-center bg-[#566861]"
    v-if="announcements.length > 0"
  >
    <div class="w-full text-sm text-center text-gray-300 font-extralight">
      <p>{{ currentAnnouncement.label }}</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ProductsCategories",
  data() {
    return {
      announcements: [],
      currentAnnouncementIndex: 0,
      base_url: this.$store.state.baseUrl,
    };
  },
  mounted() {
    this.fetchAnnouncements();
    setInterval(this.changeAnnouncement, 20000); // Change announcement every 20 seconds
  },
  methods: {
    fetchAnnouncements() {
      axios
        .get(this.base_url + "announcements")
        .then((response) => {
          this.announcements = response.data;
        })
        .catch((error) => console.log(error));
    },
    changeAnnouncement() {
      this.currentAnnouncementIndex =
        (this.currentAnnouncementIndex + 1) % this.announcements.length;
    },
  },
  computed: {
    currentAnnouncement() {
      return this.announcements[this.currentAnnouncementIndex] || {};
    },
  },
};
</script>
