import axios from "axios";
import CryptoJS from "crypto-js";
// import router from "@/router";

const state = {
  user: null,
  token: null,
  message: null,
  baseUrl: "https://admin.pokkada.com/api/",
  // baseUrl: "http://pokadaa-backend.test/api/",
  isAuthenticated: false,
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setToken(state, token) {
    state.token = token;
  },
  setMessage(state, message) {
    state.message = message;
  },
  setIsAuthenticated(state, isAuthenticated) {
    state.isAuthenticated = isAuthenticated;
  },
};

const actions = {
  async login({ commit, state, dispatch }, credentials) {
    try {
      const response = await axios.post(`${state.baseUrl}login`, credentials);
      const token = response.data.token;
      sessionStorage.setItem("authToken", token);

      const tokenExpiry = response.data.expires_at;
      localStorage.setItem("tokenExpiry", tokenExpiry);

      dispatch("startTokenExpiryTimer", tokenExpiry);

      const message = response.data.message;
      commit("setMessage", message);

      const encryptedData = CryptoJS.AES.encrypt(
        JSON.stringify(response.data.user),
        "amazonlike"
      ).toString();
      localStorage.setItem("userData", encryptedData);

      commit("setUser", response.data.user);
      commit("setIsAuthenticated", true);
    } catch (error) {
      commit("setIsAuthenticated", false);
      if (error.response) {
        const message = error.response.data.message;
        commit("setMessage", message);
      }
      throw error;
    }
  },
  async forgotPassword({ commit, state }, credentials) {
    try {
      const response = await axios.post(
        `${state.baseUrl}forgot/password`,
        credentials
      );
      const message = response.data.message;
      commit("setMessage", message);
    } catch (error) {
      // commit("setIsAuthenticated", false);
      if (error.response) {
        const message = error.response.data.message;
        commit("setMessage", message);
      }
      throw error;
    }
  },
  async register({ commit, state }, credentials) {
    try {
      const response = await axios.post(
        `${state.baseUrl}register`,
        credentials
      );
      const message = response.data.message;
      commit("setMessage", message);
    } catch (error) {
      if (error.response) {
        const message = error.response.data.message;
        commit("setMessage", message);
      }
      throw error;
    }
  },

  startMidnightLogoutTimer({ dispatch }, tokenExpiry) {
    const expiryTime = new Date(tokenExpiry).getTime();
    const timeDifference = expiryTime - Date.now();

    if (timeDifference > 0) {
      setTimeout(() => {
        dispatch("logout");
      }, timeDifference);
    } else {
      console.log("Token has already expired");
      // Perform any necessary actions for an expired token
    }
  },

  logout({ commit }) {
    commit("setUser", null);
    commit("setToken", null);
    commit("setIsAuthenticated", false);
    sessionStorage.removeItem("authToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("tokenExpiry");
    window.location.href = "/";
  },
};

const getters = {
  getUser: (state) => state.user,
  getIsAuthenticated: (state) => state.isAuthenticated,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
