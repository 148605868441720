import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/login",
    name: "LoginVIew",
    component: () => import("../views/Auth/LoginView.vue"),
  },
  {
    path: "/forgot-password",
    name: "ForgotPasswordView",
    component: () => import("../views/Auth/ForgotPasswordView.vue"),
  },
  {
    path: "/register",
    name: "RegisterVIew",
    component: () => import("../views/Auth/RegisterView.vue"),
  },
  {
    path: "/collections/shop-all",
    name: "ProductsView",
    component: () => import("../views/productsView.vue"),
  },
  {
    path: "/product/:id/:slug",
    name: "ProductView",
    component: () => import("../views/ProductView.vue"),
  },
  {
    path: "/category/:id/:slug",
    name: "CategoryView",
    component: () => import("../views/CategoryView.vue"),
  },
  {
    path: "/cart",
    name: "CartView",
    component: () => import("../views/CartView.vue"),
  },
  {
    path: "/profile",
    name: "ProfileView",
    component: () => import("../views/ProfileView.vue"),
  },
  {
    path: "/orders",
    name: "OrdersView",
    component: () => import("../views/OrdersView.vue"),
  },
  {
    path: "/order",
    name: "OrderView",
    component: () => import("../views/OrderView.vue"),
  },
  {
    path: "/orders",
    name: "OrdersView",
    component: () => import("../views/OrdersView.vue"),
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: () => import("../views/CheckoutView.vue"),
    // meta: { requiresAuth: true }, // This route requires authentication
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/founder",
    name: "AboutFounder",
    component: () => import("../views/AboutSafura.vue"),
  },
  {
    path: "/privacy-policy",
    name: "PrivacyView",
    component: () => import("../views/PrivacyPolicy.vue"),
  },
  {
    path: "/return-policy",
    name: "ReturnPolicy",
    component: () => import("../views/ReturnPolicy.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/ContactView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const authToken = localStorage.getItem("authToken");

  if (to.meta.requiresAuth && authToken) {
    // If the route requires authentication and the user is not authenticated, redirect to the login page
    next("/login");
  } else {
    // Allow access to the route
    next();
  }
});

export default router;
