<template>
  <div class="py-3" v-if="products">
    <h1
      class="pb-2 mt-10 text-base font-semibold text-gray-700 uppercase md:mt-44 text-start"
    >
      Our beautiful Products
    </h1>
    <p class="pb-3 text-[11px] font-light text-gray-700 uppercase text-start">
      Designed In Ghana. available world wide.
    </p>
    <product-view :products="products" />
  </div>
</template>
<script>
import axios from "axios";
import ProductView from "../ProductLayout.vue";
export default {
  name: "ProductsCategories",
  components: {
    ProductView,
  },
  data() {
    return {
      products: null,
      base_url: this.$store.state.baseUrl,
    };
  },
  mounted() {
    axios
      .get(this.base_url + "latest/products")
      .then((response) => {
        this.products = response.data;
      })
      .catch((error) => console.log(error));
  },
};
</script>
