<template>
  <div class="p-3" v-if="banners">
    <div class="grid grid-cols-2 gap-5 pt-10 md:pt-10 lg:grid-cols-3">
      <a
        href="#"
        class="relative w-full h-56 overflow-hidden md:h-144"
        v-for="banner in banners"
        :key="banner.id"
      >
        <img
          :src="banner.photo"
          class="absolute inset-0 transition-transform duration-300 ease-in-out transform md:w-full h-60 md:h-144 hover:scale-110"
          alt=""
        />
      </a>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "3BannerCards",
  components: {},
  data() {
    return {
      banners: null,
      base_url: this.$store.state.baseUrl,
    };
  },
  mounted() {
    axios
      .get(this.base_url + "under-main-banners")
      .then((response) => {
        this.banners = response.data.slice(0, 3);
      })
      .catch((error) => console.log(error));
  },
  methods: {
    truncate(str, n) {
      return str.length > n ? str.substr(0, n - 1) + "..." : str;
    },
    sanitizeTitle(title) {
      var slug = "";
      // Change to lower case
      var titleLower = title.toLowerCase();
      // Letter "e"
      slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, "e");
      // Letter "a"
      slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, "a");
      // Letter "o"
      slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, "o");
      // Letter "u"
      slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, "u");
      // Letter "d"
      slug = slug.replace(/đ/gi, "d");
      // Trim the last whitespace
      slug = slug.replace(/\s*$/g, "");
      // Change whitespace to "-"
      slug = slug.replace(/\s+/g, "-");

      return slug;
    },
  },
};
</script>
