// import Swal from "sweetalert2/dist/sweetalert2.js";

const state = {
  baseUrl: "https://admin.pokkada.com/api/",
  // baseUrl: "http://pokadaa-backend.test/api/",
  cart: JSON.parse(localStorage.getItem("cart")) || [],
  loading: false,
  showSidebar: false,
};
const mutations = {
  setMessage(state, message) {
    state.message = message;
  },

  // Mutation
  addToCart(state, { product, quantity, properties }) {
    const existingProductIndex = state.cart.findIndex(
      (item) => item.id === product.id
    );

    if (existingProductIndex !== -1) {
      state.cart[existingProductIndex].quantity += quantity;
      state.cart[existingProductIndex].totalPrice =
        state.cart[existingProductIndex].quantity *
        state.cart[existingProductIndex].price;
    } else {
      product.quantity = quantity;
      product.properties = properties;
      product.photo = product.photos[0];
      product.totalPrice = quantity * product.price;
      state.cart.push(product);
    }

    // Save the cart to localStorage
    localStorage.setItem("cart", JSON.stringify(state.cart));

    // Commit the changes
    this.commit("saveCart");
  },

  // Action
  // updateTotalWeight({ state }) {
  //   state.totalWeight = state.cart.reduce(
  //     (total, item) => total + item.weight * item.quantity,
  //     0
  //   );
  // },

  saveCart(state) {
    window.localStorage.setItem("cart", JSON.stringify(state.cart));
  },

  removeFromCart(state, productIndex) {
    state.cart.splice(productIndex, 1);

    localStorage.setItem("cart", JSON.stringify(state.cart));
  },

  loading(state, newLoading) {
    state.loading = newLoading;
  },

  setSidebarVisibility(state, isVisible) {
    state.showSidebar = isVisible;
  },
};
const actions = {
  updateCartFromLocalStorage({ commit }) {
    const cartItems = JSON.parse(localStorage.getItem("cart")) || [];
    commit("saveCart", cartItems);
  },
  openSidebar({ commit }) {
    commit("setSidebarVisibility", true);
  },

  closeSidebar({ commit }) {
    commit("setSidebarVisibility", false);
  },

  addToCart({ commit }, { product, quantity }) {
    commit("addToCart", product, quantity);
  },

  removeProduct({ commit, state }, product) {
    const index = state.cart.findIndex((item) => item.id === product.id);

    if (index !== -1) {
      commit("removeFromCart", index);
    }
  },
};
const getters = {
  totalWeight(state) {
    // Calculate the total weight based on the items in the cart
    return state.cart.reduce(
      (total, item) => total + item.weight * item.quantity,
      0
    );
  },
  count: (state) => state.cart.length,
  cartItems: (state) => state.cart,
  cartTotal(state) {
    return state.cart.reduce((total, item) => {
      let itemTotal = item.price * item.quantity; // Base price of the product

      // Add the price of each property
      if (item.properties && item.properties.length > 0) {
        itemTotal += item.properties.reduce((propTotal, prop) => {
          return propTotal + prop.price * item.quantity;
        }, 0);
      }

      return total + itemTotal;
    }, 0);
  },

  loading(state) {
    return state.loading;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
