<template>
  <div class="grid grid-cols-2 gap-2 mt-2 md:gap-3 lg:grid-cols-5">
    <router-link
      v-for="product in products"
      :key="product.id"
      :to="{
        name: 'ProductView',
        params: {
          id: product.id,
          slug: product ? sanitizeTitle(product.name) : 'product',
        },
      }"
      class="relative overflow-hidden group"
    >
      <div
        class="relative transition-all md:w-full h-60 md:h-[507px] group-hover:brightness-50 mt-5"
      >
        <div
          class="absolute inset-0 transition-opacity bg-black opacity-0 group-hover:opacity-50"
        ></div>

        <img
          :src="product.photos[0]"
          class="object-cover w-full h-full transition duration-500 cursor-pointer group-hover:scale-100"
          alt=""
        />

        <a
          href="/collections/shop-all"
          class="absolute inset-0 flex items-center justify-center w-full h-full transition-opacity opacity-0 group-hover:opacity-100"
        >
          <div
            class="flex items-center justify-center w-32 p-2 text-sm font-light text-center text-white bg-[#566861] rounded-full"
          >
            Shop Now
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5 ml-1 font-bold stroke-2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
              />
            </svg>
          </div>
        </a>
      </div>

      <!-- Product Details -->
      <div class="flex flex-col items-center justify-end mt-2">
        <p class="text-sm text-center text-black truncate font-extralight">
          {{ product.name }}
        </p>
        <p class="mt-2 text-sm text-gray-600 truncate font-extralight">
          {{ formattedAmount(Number(product.display_price)) }}
        </p>
      </div>
    </router-link>
  </div>
</template>
<script>
export default {
  name: "ProductLayout",
  props: {
    products: Object,
  },
  methods: {
    truncate(str, n) {
      return str.length > n ? str.substr(0, n - 1) + "..." : str;
    },
    sanitizeTitle(title) {
      var slug = "";
      // Change to lower case
      var titleLower = title.toLowerCase();
      // Letter "e"
      slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, "e");
      // Letter "a"
      slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, "a");
      // Letter "o"
      slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, "o");
      // Letter "u"
      slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, "u");
      // Letter "d"
      slug = slug.replace(/đ/gi, "d");
      // Trim the last whitespace
      slug = slug.replace(/\s*$/g, "");
      // Change whitespace to "-"
      slug = slug.replace(/\s+/g, "-");

      return slug;
    },
  },
};
</script>
