<template>
  <div class="pt-10 md:pt-10 md:max-w-full" v-if="products">
    <h1 class="pb-2 text-base font-semibold text-gray-700 uppercase text-start">
      Explore New Arrivals
    </h1>
    <main class="mx-4 mt-5 md:mx-0">
      <vue-horizontal class="horizontal">
        <router-link
          :to="{
            name: 'ProductView',
            params: {
              id: item.id,
              slug: sanitizeTitle(item.name),
            },
          }"
          class="item"
          v-for="item in products"
          :key="item.id"
        >
          <div class="w-full pr-4">
            <img
              :src="item.photos[0]"
              class="md:w-[400px] md:h-[500px] w-72 h-64"
              alt=""
            />
            <div class="flex flex-col mt-2">
              <p class="text-sm font-semibold text-black truncate">
                {{ item.name }}
              </p>
              <p class="mt-2 text-sm font-light text-gray-600 truncate">
                {{ formattedAmount(Number(item.display_price)) }}
              </p>
            </div>
          </div>
        </router-link>
      </vue-horizontal>
    </main>
    <!-- <p
      class="text-sm leading-relaxed text-stone-500 font-light w-[460px] mt-4 mb-8"
    >
      Our reusable, organic cotton period pads are the epitome of simplicity,
      safety, and eco-friendliness.
    </p> -->
    <!-- <div class="grid grid-cols-2 gap-2 p-2 md:gamd:p-5 md:grid-cols-5 md:h-96">
      <div
        class="flex flex-col items-center justify-center p-2 space-y-5 text-center md:p-5 md:h-80"
        v-for="website_variable in website_variables"
        :key="website_variable.id"
      >
        <img
          :src="website_variable.photo"
          class="w-12 h-12 md:w-20 md:h-20"
          alt=""
        />
        <p class="md:text-2xl font-medium text-[#566861]">
          {{ website_variable.title }}
        </p>
        <p class="text-sm text-[#566861]">
          {{ website_variable.sub_title }}
        </p>
      </div>
    </div> -->
  </div>
</template>
<script>
import VueHorizontal from "vue-horizontal";
import axios from "axios";
export default {
  name: "6ProductCards",
  components: {
    VueHorizontal,
  },
  data() {
    return {
      products: null,
      base_url: this.$store.state.baseUrl,
    };
  },
  mounted() {
    axios
      .get(this.base_url + "latest/products")
      .then((response) => {
        this.products = response.data;
        this.$store.commit("loading", false);
      })
      .catch((error) => console.log(error));
    // axios
    //   .get(this.base_url + "first-homepage-variables")
    //   .then((response) => {
    //     // console.log(response);
    //     this.website_variables = response.data.slice(0, 5);
    //   })
    //   .catch((error) => console.log(error));
  },
  methods: {
    truncate(str, n) {
      return str.length > n ? str.substr(0, n - 1) + "..." : str;
    },
    sanitizeTitle(title) {
      var slug = "";
      // Change to lower case
      var titleLower = title.toLowerCase();
      // Letter "e"
      slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, "e");
      // Letter "a"
      slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, "a");
      // Letter "o"
      slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, "o");
      // Letter "u"
      slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, "u");
      // Letter "d"
      slug = slug.replace(/đ/gi, "d");
      // Trim the last whitespace
      slug = slug.replace(/\s*$/g, "");
      // Change whitespace to "-"
      slug = slug.replace(/\s+/g, "-");

      return slug;
    },
  },
};
</script>
