export const moneyMixin = {
  methods: {
    formattedAmount(amount, decimalPlaces) {
      return amount.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
      });
    },
  },
};
