<template>
  <div class="mx-auto mt-20 md:max-w-7xl md:mt-44" v-if="product">
    <div class="flex flex-col md:space-x-5 md:flex-row">
      <router-link
        :to="{
          name: 'ProductView',
          params: {
            id: product[0].id,
            slug: sanitizeTitle(product[0].name),
          },
        }"
        class="mb-5 md:mb-0"
      >
        <img
          :src="product[0].photos[0]"
          class="lg:w-[790px] lg:h-[700px]"
          alt=""
        />
      </router-link>
      <div class="flex flex-col md:justify-end md:w-6/12">
        <div class="flex flex-col">
          <p class="text-xs font-light text-stone-400">Featured</p>
          <h4 class="text-xl font-medium text-black">
            {{ product[0].name }}
          </h4>
          <p class="text-[12px] font-normal">
            {{ formattedAmount(Number(product[0].display_price)) }}
          </p>
        </div>
        <div class="flex flex-col md:mt-10">
          <p class="text-[10px] font-light leading-relaxed text-stone-600">
            {{ product[0].description }}
          </p>
          <div class="flex items-center mt-1 font-semibold text-[12px]">
            <span>Shop Now</span>
            <router-link
              :to="{
                name: 'ProductView',
                params: {
                  id: product[0].id,
                  slug: sanitizeTitle(product[0].name),
                },
              }"
              class="flex items-center justify-center w-8 h-4 ml-2 border rounded-full border-stone-400"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-4 h-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
                />
              </svg>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "3BannerCards",
  components: {},
  data() {
    return {
      product: null,
      base_url: this.$store.state.baseUrl,
    };
  },
  mounted() {
    axios
      .get(this.base_url + "featured/product")
      .then((response) => {
        this.product = response.data;
      })
      .catch((error) => console.log(error));
  },
  methods: {
    truncate(str, n) {
      return str.length > n ? str.substr(0, n - 1) + "..." : str;
    },
    sanitizeTitle(title) {
      var slug = "";
      // Change to lower case
      var titleLower = title.toLowerCase();
      // Letter "e"
      slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, "e");
      // Letter "a"
      slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, "a");
      // Letter "o"
      slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, "o");
      // Letter "u"
      slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, "u");
      // Letter "d"
      slug = slug.replace(/đ/gi, "d");
      // Trim the last whitespace
      slug = slug.replace(/\s*$/g, "");
      // Change whitespace to "-"
      slug = slug.replace(/\s+/g, "-");

      return slug;
    },
  },
};
</script>
