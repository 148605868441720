<template>
  <!-- bg-[#EDECE8] -->
  <div class="w-screen">
    <div
      v-if="
        $route.path !== '/login' &&
        $route.path !== '/register' &&
        $route.path !== '/forgot-password' &&
        $route.path !== '/checkout'
      "
    >
      <HeaderView />
    </div>

    <div class="flex items-center justify-center h-screen" v-if="loading">
      <clip-loader class="" :color="color" :loading="loading"></clip-loader>
    </div>
    <router-view />
    <div
      v-if="
        $route.path !== '/login' &&
        $route.path !== '/register' &&
        $route.path !== '/checkout' &&
        $route.path !== '/forgot-password'
      "
    >
      <FooterView />
    </div>
  </div>
</template>

<script>
import HeaderView from "@/components/partials/Header.vue";
import FooterView from "@/components/partials/Footer.vue";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
export default {
  name: "AppView",
  components: {
    FooterView,
    HeaderView,
    ClipLoader,
  },
  data() {
    return {
      color: "#000",
    };
  },
  mounted() {
    document.title = "Pokkadaa - Best online Market";
  },
  computed: {
    loading() {
      return this.$store.getters.loading;
    },
  },
};
</script>
