<template>
  <div class="pt-10 md:flex md:justify-between">
    <div class="flex items-end md:w-6/12">
      <p class="font-light w-48 text-stone-700 leading-relaxed text-[12px]">
        Our bricks-and-mortar store in Accra opened its doors in 2019.
      </p>
    </div>
    <div class="flex flex-col md:w-6/12">
      <p class="md:text-[25px] font-serif text-gray-700 leading-loose w-full">
        Since 2019, we've dedicated ourselves to curating and acquiring home
        pieces designed to blend artistry with functionality. Our mission is to
        craft handmade homewares that seamlessly integrate with nature,
        advocating for sustainably sourced and artisanal products.
      </p>
      <div class="flex items-center mt-8 font-semibold text-[14px]">
        <span>Read our story</span>
        <a
          href="/about"
          class="flex items-center justify-center w-8 h-4 ml-2 border rounded-full border-stone-400"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
            />
          </svg>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
// import axios from "axios";
export default {
  name: "6ProductCards",
  components: {},
  data() {
    return {};
  },
};
</script>
