<template>
  <transition name="fade" mode="out-in">
    <div class="flex items-center justify-center">
      <div
        :class="['alert', positionClass, alertTypeClass]"
        v-if="isAlertVisible"
      >
        <button class="close-btn" @click="closeAlert">×</button>
        {{ message }}
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "AlertComponent",
  props: {
    show: Boolean,
    message: String,
    position: String,
    duration: {
      type: Number,
      default: 5000,
    },
    type: {
      type: String,
      default: "info", // Default to info type (you can specify "success" or "error")
    },
  },
  data() {
    return {
      isAlertVisible: false,
      timer: null,
    };
  },
  computed: {
    positionClass() {
      return this.position ? `position-${this.position}` : "position-top";
    },
    alertTypeClass() {
      // Apply different background and text colors based on the alert type
      return this.type === "success"
        ? "bg-green-500 text-white"
        : this.type === "error"
        ? "bg-red-500 text-white"
        : "";
    },
  },
  methods: {
    closeAlert() {
      this.isAlertVisible = false;
    },
    setAutoCloseTimer() {
      if (this.duration > 0) {
        this.timer = setTimeout(() => {
          this.isAlertVisible = false;
        }, this.duration);
      }
    },
  },
  watch: {
    show(newValue) {
      if (newValue) {
        this.isAlertVisible = true;
        this.setAutoCloseTimer();
      } else {
        this.isAlertVisible = false;
      }
    },
  },
  mounted() {
    if (this.show) {
      this.setAutoCloseTimer();
    }
  },
  beforeUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },
};
</script>

<style scoped>
.alert {
  @apply p-1 rounded-md text-center w-auto;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.close-btn {
  @apply absolute top-1 right-2 text-lg cursor-pointer;
}

.position-top {
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}
.position-bottom {
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}
.position-left {
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}
.position-right {
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}
.position-top-left {
  top: 20px;
  left: 20px;
}
.position-top-right {
  top: 20px;
  right: 20px;
}
.position-bottom-left {
  bottom: 20px;
  left: 20px;
}
.position-bottom-right {
  bottom: 20px;
  right: 20px;
}

/* Style for success alert */
.bg-green-500 {
  background-color: #10b981;
}

/* Style for error alert */
.bg-red-500 {
  background-color: #ef4444;
}

/* Text color for both success and error alerts */
.text-white {
  color: #ffffff;
}
</style>
