<template>
  <div>
    <!-- Your main content -->

    <!-- Sidebar -->
    <div v-if="showSidebar" class="fixed inset-0 z-50 overflow-hidden">
      <div class="absolute inset-0 overflow-hidden">
        <div
          class="absolute inset-0 transition-opacity bg-gray-500 bg-opacity-75"
          @click="closeSidebar"
        ></div>

        <section class="absolute inset-y-0 right-0 flex max-w-full pl-10">
          <!-- Sidebar content -->
          <div
            class="relative w-full max-w-md bg-white shadow-xl md:max-w-lg lg:max-w-xl"
          >
            <header class="flex items-center justify-between p-4 border-b">
              <h2 class="font-light tracking-wider uppercase">Cart</h2>
              <button
                @click="closeSidebar"
                class="text-gray-500 focus:outline-none focus:text-gray-700"
              >
                <!-- Close button icon -->
                <svg
                  class="w-5 h-5 font-light"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </header>

            <!-- Cart items list -->
            <div class="md:h-[42rem] h-[35rem] p-4 overflow-y-auto">
              <div v-if="cart.length === 0" class="text-center text-gray-500">
                Your cart is empty.
              </div>
              <div v-else>
                <div
                  v-for="item in cart"
                  :key="item.id"
                  class="flex justify-between py-2 space-x-5 border-b"
                >
                  <div class="flex items-center w-24">
                    <img
                      v-if="item"
                      :src="item.photos[0]"
                      class="w-10 rounded-sm"
                      alt=""
                    />
                  </div>
                  <div class="flex flex-col justify-between space-y-2 w-52">
                    <p
                      class="text-sm font-light capitalize truncate texs text-stone-500"
                    >
                      {{ item.name }}
                    </p>
                    <p class="text-sm text-stone-700">
                      {{ formattedAmount(Number(item.display_price)) }}
                    </p>
                    <div class="flex items-center space-x-3">
                      <button
                        @click="decrementProduct(item)"
                        class="flex items-center justify-center w-6 h-6 p-1 bg-gray-100 rounded-full"
                      >
                        -
                      </button>
                      <p class="text-xs font-thin">{{ item.quantity }}</p>
                      <button
                        @click="incrementProduct(item)"
                        class="flex items-center justify-center w-6 h-6 p-1 bg-gray-100 rounded-full"
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div class="flex flex-col items-end justify-between w-24">
                    <button
                      class="flex justify-end text-sm font-semibold text-black"
                      @click="removeProductFromCart(item)"
                    >
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 24 24"
                        color="#000"
                        height="1em"
                        width="1em"
                        class="flex items-end text-end"
                        xmlns="http://www.w3.org/2000/svg"
                        style="color: rgb(0, 0, 0)"
                      >
                        <path
                          d="M15 2H9c-1.103 0-2 .897-2 2v2H3v2h2v12c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2V8h2V6h-4V4c0-1.103-.897-2-2-2zM9 4h6v2H9V4zm8 16H7V8h10v12z"
                        ></path>
                      </svg>
                    </button>
                    <!-- <p class="text-xs truncate">
                      {{ formattedAmount(Number(item.totalPrice)) }}
                    </p> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="absolute bottom-0 left-0 right-0">
              <div
                class="flex flex-col items-end text-sm font-light text-center"
              >
                <a
                  href="/checkout"
                  class="flex items-center justify-center w-full h-16 px-4 py-2 text-sm text-white uppercase bg-black"
                >
                  Go to Checkout
                </a>
                <button
                  @click="closeSidebar"
                  class="w-full h-16 px-4 py-2 text-white bg-[#566861] flex items-center justify-center"
                >
                  <!-- <div class="flex flex-col items-start">
                    <p class="text-xs uppercase">Total</p>
                    <p class="text-sm font-medium">
                      {{ formattedAmount(cartTotal) }}
                    </p>
                  </div> -->
                  <p class="text-sm font-light text-center uppercase">
                    Continue Shopping
                  </p>
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "SideBar",
  data() {
    return {
      //   showSidebar: false,
      cart: this.$store.state.cart.cart,
    };
  },
  computed: {
    // cart() {
    //   // Retrieve cart items from the store or local storage
    //   return this.$store.state.cart;
    // },
    showSidebar() {
      return this.$store.state.cart.showSidebar;
    },
    ...mapGetters("cart", ["count"]),
    ...mapGetters("cart", ["cartTotal"]),
  },
  methods: {
    removeProductFromCart(index) {
      this.$store.dispatch("cart/removeProduct", index);

      this.cart = this.$store.state.cart.cart;
    },

    openSidebar() {
      this.$store.dispatch("cart/openSidebar");
    },
    closeSidebar() {
      this.$store.dispatch("cart/closeSidebar");
    },

    incrementProduct(product) {
      const cartItems = JSON.parse(localStorage.getItem("cart"));
      const index = cartItems.findIndex(({ id }) => id === product.id);

      product.quantity++;
      product.totalPrice = product.display_price * product.quantity;

      cartItems[index].totalPrice = product.totalPrice;
      cartItems[index].quantity = product.quantity;
      localStorage.setItem("cart", JSON.stringify(cartItems));
      this.cartItems = JSON.parse(localStorage.getItem("cart"));
      this.cartTotal = this.computedCartTotal;
    },

    decrementProduct(product) {
      const cartItems = JSON.parse(localStorage.getItem("cart"));
      const index = cartItems.findIndex(({ id }) => id === product.id);

      if (product.quantity > 1) {
        product.quantity--;

        product.totalPrice = product.display_price * product.quantity;
        cartItems[index].totalPrice = product.totalPrice;
        cartItems[index].quantity = product.quantity;
        localStorage.setItem("cart", JSON.stringify(cartItems));
        this.cartItems = JSON.parse(localStorage.getItem("cart"));
      } else {
        this.removeProduct(product);
      }

      this.cartTotal = this.computedCartTotal;
    },
  },
};
</script>
<style scoped>
/* Add your scoped styles here */
/* Adjust width for smaller screens */
@media screen and (max-width: 768px) {
  .max-w-md {
    max-width: 80vw; /* Adjust as needed */
  }
}
</style>
