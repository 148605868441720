import { createStore } from "vuex";
import cart from "./modules/cart";
import auth from "./modules/auth";
import CryptoJS from "crypto-js";
import axios from "axios";

const store = createStore({
  state: {
    baseUrl: "https://oversight.pokkada.com/api/",
    // baseUrl: "http://pokadaa-backend.test/api/",
    loading: false,
    user: null,
    // publicKey: "pk_test_0eb35c0466384447e81fc138f66213bb5d6aea22",
    publicKey: "pk_live_ce09c3324b2c8efe6eed17696296e75d4eabfb1a",
    token: "Bearer " + localStorage.getItem("authToken"),
    userProfile: {
      first_name: "",
      email: "",
      phone: "",
      last_name: "",
    },
  },
  mutations: {
    updateUser(state, userInfo) {
      state.userProfile = { ...state.userProfile, ...userInfo };
    },

    setUser(state, user) {
      state.user = user;
    },
    loading(state, newLoading) {
      state.loading = newLoading;
    },
    setToken(state, token) {
      state.token = token;
    },
    setMessage(state, message) {
      state.message = message; // Mutation to update the message
    },
  },
  actions: {
    loadUser({ commit }) {
      const encryptedData = localStorage.getItem("userData");
      if (encryptedData) {
        const bytes = CryptoJS.AES.decrypt(encryptedData, "amazonlike");
        const user = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        commit("setUser", user);
      }
    },

    async updateUserInfo({ state, commit }, userInfo) {
      try {
        const userId = state.user.id;
        const baseUrl = state.baseUrl;
        const AuthStr = state.token;

        const response = await axios.patch(
          `${baseUrl}user/update/${userId}`,
          userInfo,
          {
            headers: {
              Authorization: AuthStr,
            },
          }
        );

        commit("updateUser", response.data);

        // Encrypt and store the updated user data in local storage
        const encryptedData = CryptoJS.AES.encrypt(
          JSON.stringify(response.data.user),
          "amazonlike"
        ).toString();
        localStorage.setItem("userData", encryptedData);

        return response.data;
      } catch (error) {
        console.error("Error updating user profile:", error);
        throw error;
      }
    },
  },
  modules: {
    cart,
    auth,
  },
});

export default store;
