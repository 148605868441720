import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./input.css";
import "flowbite";
// import "./cookie-plugin";
import Select2 from "vue3-select2-component";
import axios from "axios";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { moneyMixin } from "./moneyMixin";

createApp(App)
  .use(store)
  .use(router)
  .use(VueSweetalert2)
  .use(Select2)
  .mixin(moneyMixin)
  .mount("#app")
  .$nextTick(() => {
    // Load the user data when the application starts
    store.dispatch("loadUser");
    // store.dispatch("auth");
  });
// created() {
//   // Dispatch the init action when the Vue instance is created
//   this.$store.dispatch('auth/init');
// }

// use beforeEach route guard to set the language
router.beforeEach((to, from, next) => {
  // use the language from the routing param or default language
  next();
});

axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    if (config.showLoader) {
      // this.$store.commit('loading', true)
    }
    return config;
  },
  function (error) {
    // Do something with request error
    if (error.config.showLoader) {
      // this.$store.commit('loading', false)
    }
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Do something with response data

    if (response.config.showLoader) {
      // this.$store.commit('loading', true)
    }
    return response;
  },
  function (error) {
    if (error.config.showLoader) {
      // this.$store.commit('loading', false)
    }
    // Do something with response error
    return Promise.reject(error);
  }
);
