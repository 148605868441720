<template>
  <div class="max-w-full">
    <HomeBanner />
    <div class="mb-10">
      <div class="flex flex-col w-screen px-2 lg:px-10">
        <WhyPokaada />
        <HomePage2CategoryCards />
        <ThreeStepSection />
        <HomePage6ProductCards />
        <div class="grid grid-cols-1 gap-8 pt-10 md:grid-cols-2">
          <!-- <div
            class="flex flex-col items-center justify-center order-last p-5 text-white bg-[#566861] h-44 md:h-144 md:-order-last"
          >
            <h1 class="text-2xl font-bold text-center w-52 md:text-4xl md:w-80">
              {{ ad[0].name }}
            </h1>
            <p class="text-sm font-light text-center">
              {{ ad[0].description }}
            </p>
          </div> -->
          <img
            v-for="(ad, index) in ads"
            :key="index"
            :src="ad.photo"
            class="inset-0 w-[45rem] transition-transform duration-300 ease-in-out transform h-72 md:h-[55rem] hover:scale-95"
            alt="product"
          />
        </div>

        <FeaturedProduct />
        <HomePage2ProductCategories />
        <!-- <HomePage3CategoriesCard class="mt-8" /> -->
        <!-- <HomePageBlogTiles class="mt-20" /> -->
        <!-- <div class="home"></div> -->

        <div
          v-if="showModal"
          class="fixed inset-0 z-50 flex items-center justify-center px-2 bg-black bg-opacity-50"
        >
          <div
            class="w-full bg-white rounded-md shadow-md md:w-5/12"
            v-show="newsletterBanner"
          >
            <!-- Close button -->
            <button
              @click="toggleModal"
              class="absolute top-3 right-3 flex justify-center items-center text-white font-thin rounded-full text-sm p-1 bg-[#566861]"
            >
              <svg
                aria-hidden="true"
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="sr-only">Close modal</span>
            </button>

            <!-- Modal content -->
            <div class="">
              <div class="grid grid-cols-2">
                <img
                  v-if="newsletterBanner.photo"
                  :src="newsletterBanner.photo"
                  class="w-full md:h-96 h-72"
                  alt=""
                />
                <img src="@/assets/plant-vace.jpg" v-else alt="" />
                <div class="flex flex-col items-center justify-center px-4">
                  <div class="flex flex-col justify-start">
                    <alert
                      :show="showSuccessAlert"
                      :message="message"
                      position="top-left"
                      type="success"
                      class="text-xs font-light"
                    />
                    <alert
                      :show="showErrorAlert"
                      :message="message"
                      position="top-left"
                      type="error"
                      class="text-xs font-light"
                    />
                  </div>
                  <h1 class="text-xl text-center">
                    {{ newsletterBanner.name ?? "Sign up today" }}
                  </h1>
                  <p class="mt-3 mb-2 text-xs text-center text-stone-500">
                    {{
                      newsletterBanner.description ??
                      "Sign up for our newsletter and get amazing offers, latest products and many more today"
                    }}
                  </p>
                  <input
                    type="email"
                    placeholder="Email"
                    class="border border-[#566861] shadow-sm p-2 h-10 w-full"
                    v-model="email"
                  />
                  <Button
                    @click="newsletter"
                    :name="'Submit'"
                    class="w-full mt-2 bg-[#566861]"
                    :color="'white'"
                    :bg="'[#566861]'"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="showModal"
          class="fixed inset-0 z-40 bg-black opacity-25"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import Alert from "@/components/Alert.vue";
import Button from "@/components/forms/Button.vue";
// import Input from "@/components/forms/Input.vue";
import HomeBanner from "@/components/partials/Banner.vue";
import ThreeStepSection from "@/components/Homepage/ThreeStepSection.vue";
import WhyPokaada from "@/components/Homepage/WhyPokaada.vue";
import FeaturedProduct from "@/components/Homepage/FeaturedProduct.vue";
import HomePage6ProductCards from "@/components/Homepage/HomePage6ProductCards.vue";
import HomePage2CategoryCards from "@/components/Homepage/HomePage2CategoryCards.vue";
import HomePage2ProductCategories from "@/components/Homepage/HomePage2ProductCategories.vue";
// import HomePage3CategoriesCard from "@/components/Homepage/HomePage3CategoriesCard.vue";
// import HomePageBlogTiles from "@/components/Homepage/HomePageBlogTiles.vue";
import axios from "axios";
export default {
  name: "HomeView",
  components: {
    HomeBanner,
    ThreeStepSection,
    HomePage6ProductCards,
    HomePage2CategoryCards,
    HomePage2ProductCategories,
    WhyPokaada,
    FeaturedProduct,
    // HomePageBlogTiles,
    Button,
    Alert,
  },
  data() {
    return {
      showModal: false,
      ads: [],
      email: null,
      base_url: this.$store.state.baseUrl,
      message: null,
      showSuccessAlert: false,
      showErrorAlert: false,
      newsletterBanner: null,
    };
  },
  mounted() {
    let notAFirstVisitor = localStorage.getItem("notAVisitor");
    if (!notAFirstVisitor) {
      this.toggleModal();
    }
    axios
      .get(this.base_url + "big-ads")
      .then((response) => {
        this.ads = response.data;
      })
      .catch((error) => console.log(error));

    axios
      .get(this.base_url + "newsletter")
      .then((response) => {
        this.newsletterBanner = response.data;
        console.log(this.newsletterBanner);
      })
      .catch((error) => console.log(error));
  },
  methods: {
    toggleModal: function () {
      this.showModal = !this.showModal;
      localStorage.setItem("notAVisitor", "notAFirstVisitor");
    },

    newsletter() {
      if (this.validateEmail(this.email)) {
        axios
          .post(this.base_url + "newsletter", { email: this.email })
          .then((response) => {
            this.message = response.data.message;
            this.showSuccessAlert = true;
            window.location.reload();
          })
          .catch((error) => {
            this.message = error.response.data.message;
            this.showErrorAlert = true;
          });
      } else {
        console.error("Invalid email address");
      }
    },

    validateEmail(email) {
      // Very basic email validation
      const re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
  },
};
</script>
