<template>
  <div
    v-if="banners"
    class="object-center w-screen bg-center bg-no-repeat bg-cover"
    :style="{
      'background-image': 'url(' + banners[0].photo + ')',
      width: '100%',
    }"
  >
    <div class="pb-10" v-show="banners.length > 0">
      <div class="flex flex-col items-center justify-center h-screen">
        <div class="flex flex-col items-center">
          <h1 class="text-2xl text-white">{{ banners[0].name }}</h1>
          <a
            :href="
              $router.resolve({
                name: 'CategoryView',
                params: {
                  id: banners[0].category.id,
                  slug: sanitizeTitle(banners[0].category.name),
                },
              }).href
            "
            class="flex items-center hover:bg-[#566861] justify-center px-4 py-2 mt-3 text-white uppercase border border-white rounded-sm"
          >
            <span>Shop Now</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import VueHorizontal from "vue-horizontal";
export default {
  name: "BannerView",
  components: {
    // VueHorizontal,
  },
  data() {
    return {
      banners: null,
      base_url: this.$store.state.baseUrl,
    };
  },
  mounted() {
    axios
      .get(this.base_url + "main-banners")
      .then((response) => {
        this.banners = response.data.slice(0, 1);
      })
      .catch((error) => console.log(error));
  },
  methods: {
    sanitizeTitle(title) {
      var slug = "";
      // Change to lower case
      var titleLower = title.toLowerCase();
      // Letter "e"
      slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, "e");
      // Letter "a"
      slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, "a");
      // Letter "o"
      slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, "o");
      // Letter "u"
      slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, "u");
      // Letter "d"
      slug = slug.replace(/đ/gi, "d");
      // Trim the last whitespace
      slug = slug.replace(/\s*$/g, "");
      // Change whitespace to "-"
      slug = slug.replace(/\s+/g, "-");

      return slug;
    },
  },
};
</script>
