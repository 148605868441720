<template>
  <div class="flex flex-col items-center px-4 lg:p-10 md:h-96 md:mt-36">
    <div class="w-full">
      <div class="grid grid-cols-1 md:grid-cols-2">
        <!-- <div class="flex flex-col"> -->
        <div class="flex flex-col">
          <p class="tracking-wider">Stay in the know</p>
          <div class="float-left">
            <alert
              :show="showSuccessAlert"
              :message="message"
              position="left"
              type="success"
              class="text-xs font-light"
            />
            <alert
              :show="showErrorAlert"
              :message="message"
              position="eft"
              type="error"
              class="text-xs font-light"
            />
          </div>
          <!-- <input
              type="text"
              placeholder="Name"
              v-model="name"
              class="block h-10 px-2 mt-5 text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none md:w-96 focus:border-gray-400 focus:outline-none focus:ring-0"
            /> -->
          <input
            type="text"
            v-model="email"
            placeholder="Email"
            class="block h-10 px-2 mt-5 text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none md:w-96 focus:border-gray-400 focus:outline-none focus:ring-0"
          />
          <button
            @click="newsletter"
            class="p-2 mt-5 text-sm font-light text-white bg-[#566861] md:w-96"
          >
            Subscribe
          </button>
        </div>
        <!-- </div> -->
        <div class="grid gap-5 mt-5 md:grid-cols-3 md:mt-0">
          <div>
            <h1 class="pb-[10px] font-normal text-[1rem]">Social</h1>
            <div class="flex flex-row space-x-3">
              <a
                target="__blank"
                class="hover:underline underline-offset-4"
                href="https://www.instagram.com/pokka_daa/"
                ><img src="/img/instagram.png" class="w-5 h-5" alt=""
              /></a>
              <a
                target="__blank"
                href="https://www.facebook.com/profile.php?id=100046421598552&mibextid=ZbWKwL"
                class="hover:underline underline-offset-4"
                ><img src="/img/facebook.png" class="w-5 h-5" alt=""
              /></a>

              <a
                target="__blank"
                class="hover:underline underline-offset-4"
                href="https://wa.me/+233556377172"
                ><img src="/img/whatsapp.png" class="w-5 h-5" alt=""
              /></a>
            </div>
          </div>
          <div>
            <h1 class="pb-[10px] font-normal text-[1rem]">Info</h1>

            <ul class="space-y-[1rem] text-[0.9rem] font-light">
              <li>
                <a href="/about" class="hover:underline underline-offset-4"
                  >About us</a
                >
              </li>
              <li>
                <a class="hover:underline underline-offset-4" href="/contact"
                  >Contact us</a
                >
              </li>
              <li>
                <a
                  class="hover:underline underline-offset-4"
                  href="/return-policy"
                >
                  Return & refund Policy
                </a>
              </li>
              <li>
                <a
                  class="hover:underline underline-offset-4"
                  href="/privacy-policy"
                >
                  Privacy and security</a
                >
              </li>
            </ul>
          </div>
          <div>
            <h1 class="pb-[10px] font-normal text-[1rem]">Contact</h1>
            <ul class="space-y-[1rem] text-[0.9rem] font-light">
              <li class="capitalize">
                #2 Lion Street Haatso <br />
                Accra Ghana
              </li>
              <li class="underline underline-offset-8">
                <a href="tel:+233556377172">(+233)55.637.7172</a>
              </li>
              <li>info@pokkadaa.com</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <hr />
  <div class="flex justify-center mt-2">
    <p class="text-xs font-light text-center">©️ 2024 Pokkadaa</p>
  </div>
</template>
<script>
import axios from "axios";
import Alert from "@/components/Alert.vue";
export default {
  name: "FooterView",
  components: {
    Alert,
  },
  data() {
    return {
      email: null,
      // name: null,
      base_url: this.$store.state.baseUrl,
      message: null,
      showSuccessAlert: false,
      showErrorAlert: false,
    };
  },
  methods: {
    newsletter() {
      if (this.validateEmail(this.email)) {
        axios
          .post(this.base_url + "newsletter", { email: this.email })
          .then((response) => {
            this.message = response.data.message;
            this.showSuccessAlert = true;
            // console.log(response);
            this.email = null;
          })
          .catch((error) => {
            this.message = error.response.data.message;
            this.showErrorAlert = true;
          });
      } else {
        console.error("Invalid email address");
      }
    },

    validateEmail(email) {
      // Very basic email validation
      const re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
  },
};
</script>
